import React, { FC, useMemo } from 'react';
import useStyles from './useStyles';
import { ReactComponent as ArrowGreen } from '../../../../../../../../assets/icons/arrow-green.svg';
import { ReactComponent as ArrowRed } from '../../../../../../../../assets/icons/arrow-red.svg';
import { battleCardColors } from 'src/constants/BATTLE_CARD_COLORS';
import { RevenueDevelopmentType } from '../../../../../../../../types';

type SegmentProps = {
  revenueDevelopment?: RevenueDevelopmentType[];
};

const Segment: FC<SegmentProps> = ({ revenueDevelopment }) => {
  const classes = useStyles();

  const lastReversedSegmentsRevenue = useMemo(() => {
    return revenueDevelopment
      ?.map((item) => {
        return [...item.segmentsRevenue].reverse();
      })
      .slice(-1)[0];
  }, [revenueDevelopment]);

  const getSegmentBlockArrow = (value: number) => {
    if (value > 0) {
      return (
        <div
          className={classes.segmentItemBlockArrow}
          style={{
            backgroundColor: '#EDF2E7',
          }}>
          <ArrowGreen />
        </div>
      );
    }
    if (value < 0) {
      return (
        <div
          className={classes.segmentItemBlockArrow}
          style={{
            backgroundColor: '#FFF5F5',
          }}>
          <ArrowRed />
        </div>
      );
    }
    return;
  };

  const getDevelopmentValue = (value: number | null) => {
    if (value || value === 0) {
      return `${value}%`;
    }
    return 'n/a';
  };

  return (
    <div className={classes.segment}>
      {lastReversedSegmentsRevenue?.map((item, index) => (
        <div key={item.businessUnit} className={classes.segmentItem}>
          <div
            className={classes.segmentItemLine}
            style={{
              backgroundColor: battleCardColors[lastReversedSegmentsRevenue?.length - 1 - index],
            }}
          />
          <div>
            <div className={classes.segmentItemText}>
              Segment {lastReversedSegmentsRevenue?.length - index}
            </div>
            <div className={classes.segmentItemBlock}>
              {getSegmentBlockArrow(item.development as number)}
              <div style={{ fontSize: 16, lineHeight: '19px' }}>
                {getDevelopmentValue(item.development)}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Segment;
