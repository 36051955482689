import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    width: '60%',
    minHeight: '450px',
    padding: '0px 20px 111px',
    backgroundColor: COLORS.white1,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    overflow: 'auto',
    borderRadius: 8,
  },
  xAxisContainer: {
    position: 'absolute',
    flexDirection: 'column',
    bottom: 69,
    height: 47,
  },
  xAxisTopLine: {
    marginLeft: '13px',
    height: '3px',
    backgroundColor: COLORS.blue3,
  },
  xAxisLabelsContainer: {
    columnGap: '15px',
    paddingTop: '16px',
    paddingLeft: '5px',
    display: 'flex',
  },
  xAxisLabel: {
    textAlign: 'center',
    fontSize: '16px',
    width: '78px',
    color: COLORS.black1,
  },
  chartContainer: {
    position: 'relative',
  },
  customTooltip: {
    border: `1px solid ${COLORS.gray3}`,
    backgroundColor: COLORS.white1,
    padding: 10,
    borderRadius: 8,
  },
  customTooltipName: {
    marginBottom: 10,
  },
  customTooltipRevenue: {
    color: COLORS.green4,
    marginBottom: 10,
  },
}));

export default useStyles;
