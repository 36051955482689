import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_COUNT_OF_ITEMS } from 'src/constants/dafaultCountOfItems';
import {
  editCustomerRowSecondSurvey,
  getSecondSurvey as getSecondSurveyAction,
  saveSecondSurvey,
  submitSecondSurvey,
} from 'src/modules/surveys/actions';
import { Store } from 'src/types';
import { NoDataPlaceholder } from 'src/components/common/NoDataPlaceholder';
import { Pagination } from 'src/components/common/Pagination';
import { MonitoringTable } from 'src/components/common/MonitoringTable';
import { Loader } from 'src/components/common/Loader';
import useStyles from './useStylese';
import { ButtonBase } from 'src/components/common/ButtonBase';

export const SecondSurvey: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_COUNT_OF_ITEMS);

  const { secondSurvey, loading, firstButtonLoading, secondButtonLoading } = useSelector(
    (state: Store) => state.surveys,
  );

  const { screenNames } = useSelector((state: Store) => state.app);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const goToPage = useCallback((page: number) => {
    setPage(page);
  }, []);

  const onEditCustomerOfSecondSurvey = useCallback(
    (data) => {
      dispatch(editCustomerRowSecondSurvey(data));
    },
    [dispatch],
  );

  const saveSurvey = useCallback(() => {
    if (secondSurvey && !firstButtonLoading) {
      const preparedSurvey = secondSurvey.customerData.map((customer) => ({
        id: customer.id,
        status: customer.status,
        comment: customer.comment,
        expectedOutOfRealizedIncreases: customer.expectedOutOfRealizedIncreases,
      }));

      dispatch(saveSecondSurvey.request({ customerData: preparedSurvey }));
    }
  }, [dispatch, firstButtonLoading, secondSurvey]);

  const submitSurvey = useCallback(() => {
    if (secondSurvey && !secondButtonLoading) {
      const preparedSurvey = secondSurvey.customerData.map((customer) => ({
        id: customer.id,
        status: customer.status,
        comment: customer.comment,
        expectedOutOfRealizedIncreases: customer.expectedOutOfRealizedIncreases,
      }));

      dispatch(submitSecondSurvey.request({ customerData: preparedSurvey }));
    }
  }, [dispatch, secondButtonLoading, secondSurvey]);

  const getSecondSurvey = useCallback(
    ({
      sortBy,
      pageNumber,
      itemsOnPage,
    }: {
      sortBy: string;
      itemsOnPage: number;
      pageNumber: number;
    }) => {
      dispatch(
        getSecondSurveyAction.request({
          pageNumber,
          itemsOnPage,
          sortBy,
          sortOrder: 'asc',
        }),
      );
    },
    [dispatch],
  );

  const isAllowModifyingTable = useCallback(() => {
    const screenName = screenNames.find((screen) => screen.screenName === 'SECOND_SURVEY');

    if (screenName) {
      return screenName.allowModifying;
    }

    return false;
  }, [screenNames]);

  const EnhancedContainer = useMemo(() => {
    if (loading) {
      return <Loader isLoading={true} marginTop={40} />;
    }

    if (!secondSurvey) {
      return <NoDataPlaceholder text="No data yet. Please try again later." />;
    }

    return (
      <div>
        <div className={classes.buttonsContainer}>
          <ButtonBase
            title="Save"
            containerStyle={classes.button}
            variant="contained"
            isLoading={firstButtonLoading}
            onClick={saveSurvey}
            disabled={!isAllowModifyingTable()}
          />
          <ButtonBase
            title="Submit"
            containerStyle={classes.button}
            variant="contained"
            isLoading={secondButtonLoading}
            onClick={submitSurvey}
            disabled={!isAllowModifyingTable()}
          />
        </div>
        <MonitoringTable
          onEditCustomerRow={onEditCustomerOfSecondSurvey}
          data={secondSurvey}
          allowModifying={isAllowModifyingTable()}
        />
        <Pagination
          totalPages={secondSurvey.pageable.totalPages}
          setRowPerPage={handleChangeRowsPerPage}
          setPage={handleChangePage}
          page={page}
          rowPerPage={rowsPerPage}
          marginTop={4}
          goToPage={goToPage}
        />
      </div>
    );
  }, [
    classes.button,
    classes.buttonsContainer,
    firstButtonLoading,
    goToPage,
    isAllowModifyingTable,
    loading,
    onEditCustomerOfSecondSurvey,
    page,
    rowsPerPage,
    saveSurvey,
    secondButtonLoading,
    secondSurvey,
    submitSurvey,
  ]);

  useEffect(() => {
    getSecondSurvey({
      sortBy: 'status',
      pageNumber: page === 0 ? page : page - 1,
      itemsOnPage: rowsPerPage,
    });
  }, [getSecondSurvey, page, rowsPerPage]);

  return <div>{EnhancedContainer}</div>;
};
