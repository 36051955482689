import React from 'react';

import { Typography } from '@mui/material';
import { Grid } from '@mui/material';

import Img1 from 'src/assets/images/home-image-1.png';
import Img2 from 'src/assets/images/home-image-2.png';
import Img3 from 'src/assets/images/home-image-3.png';
import Img4 from 'src/assets/images/home-image-4.png';
import Img5 from 'src/assets/images/home-image-5.png';
import Img6 from 'src/assets/images/home-image-6.png';
import Img7 from 'src/assets/images/home-image-7.png';
import Img8 from 'src/assets/images/home-image-8.png';
import Img9 from 'src/assets/images/home-image-9.png';

import useStyles from './useStyles';
import { useNavigate } from 'react-router-dom';
import { HomePageCard, Store } from 'src/types';
import { PieProgress } from '../../common/PieProgress';
import { useSelector } from 'react-redux';

const getCardsData = (isSurveyResultAvailable?: boolean) => {
  return [
    {
      img: Img1,
      title: 'Contract inventory',
      url: '/configure-levels/touchable-revenue/survey-result',
      disabled: !isSurveyResultAvailable,
      bgColor: 'rgba(0, 86, 96, 0.7)',
      number: 1,
    },
    {
      img: Img2,
      title: 'Guidelines and escalation rules',
      url: 'configure-levels/customer-specific-targets/escalation',
      disabled: !isSurveyResultAvailable,
      bgColor: 'rgba(59, 58, 112, 0.7)',
      number: 4,
    },
    {
      img: Img8,
      title: 'Training sessions',
      bgColor: 'rgba(153, 44, 44, 0.7)',
      number: 7,
    },
    {
      img: Img4,
      title: 'Campaign target',
      url: '/configure-levels/campaign-target/target-given',
      disabled: !isSurveyResultAvailable,
      bgColor: 'rgba(0, 86, 96, 0.7)',
      number: 2,
    },
    {
      img: Img5,
      title: 'Sales incentives',
      bgColor: 'rgba(59, 58, 112, 0.7)',
      number: 5,
    },
    {
      img: Img6,
      title: 'Big deal coachings',
      url: 'configure-levels/customer-specific-targets/campaign-incentives',
      disabled: !isSurveyResultAvailable,
      bgColor: 'rgba(153, 44, 44, 0.7)',
      number: 8,
    },
    {
      img: Img7,
      title: 'Customer specific targets',
      url: '/configure-levels/customer-specific-targets/eoi-weighting',
      bgColor: 'rgba(0, 86, 96, 0.7)',
      number: 3,
    },
    {
      img: Img3,
      title: 'Supporting material',
      url: '/prepare-implementation/support-material/battlecards-menu',
      disabled: !isSurveyResultAvailable,
      bgColor: 'rgba(59, 58, 112, 0.7)',
      number: 6,
    },
    {
      img: Img9,
      title: 'Monitoring',
      url: '/rollout-initiative/monitoring-reports/monitoring',
      bgColor: 'rgba(153, 44, 44, 0.7)',
      number: 9,
    },
  ];
};

type Props = {
  homePageCards: HomePageCard[];
};

export const HomeStatusesOverview: React.FC<Props> = ({ homePageCards }) => {
  const classes = useStyles();
  const { surveyResultAccessing } = useSelector((state: Store) => state.surveys);

  const navigate = useNavigate();

  const handeNavigate = (url: string) => {
    navigate(url);
  };

  const cards = getCardsData(surveyResultAccessing?.allowedSurveyResultPage);

  const preparedCards = homePageCards.map((item, index) => ({
    ...item,
    img: cards[index].img,
    url: cards[index].url,
    title: cards[index].title,
    disabled: cards[index].disabled,
    bgColor: cards[index].bgColor,
    number: cards[index].number,
  }));

  return (
    <Grid className={classes.container} container>
      {preparedCards.map((item) => (
        <Grid
          onClick={() => {
            if (item.url && !item.disabled) {
              handeNavigate(item.url);
            }
          }}
          item
          className={classes.item}
          key={item.img}>
          <img src={`${item.img}`} className={classes.image} alt={item.title} loading="lazy" />
          <div className={classes.bg_wrapper} style={{ backgroundColor: item.bgColor }}>
            <div className={classes.textWrapper}>
              <div style={{ color: item.bgColor }} className={classes.circle}>
                {item.number}
              </div>
              <Typography variant="subtitle1">{item.title}</Typography>
            </div>
            <div className={classes.progress}>
              <PieProgress percentage={item.progress} color="#FF7A9B" />
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};
