import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    overflowX: 'auto',
    overflowY: 'auto',
    height: 'calc(100vh - 380px)',
    boxShadow: '0px 2px 10px rgba(199, 201, 201, 0.5)',
    borderRadius: '8px',
    marginTop: 32,
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
  },
  tHeader: {
    backgroundColor: COLORS.gray4,
  },
  tHeaderRow: {
    height: 46,
    fontWeight: '700',
    color: COLORS.black2,
    paddingLeft: 15,
    fontSize: 16,
    paddingBottom: 18,
    '& th': {
      textAlign: 'center',
      backgroundColor: COLORS.blue12,
    },
  },
  tBodyRow: {
    borderBottom: `1px solid ${COLORS.gray5}`,
    height: 50,
    minHeight: 74,
    '& tr:last-child': {
      border: 'none',
    },
    '&:nth-child(even)': {
      backgroundColor: COLORS.blue13,
      '& button': {
        backgroundColor: COLORS.blue13,
      },
    },
    '& td': {
      fontSize: 16,
      color: COLORS.black1,
      height: 64,
      textAlign: 'center',
      '&:first-child': {
        paddingLeft: 10,
      },
      '&:last-child': {
        paddingRight: 10,
      },
    },
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusCircle: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    marginRight: 10,
  },
  editableCell: {
    height: 64,
    width: 100,
    textAlign: 'center',
    fontSize: 16,
  },
  dropdownContainer: {
    alignItems: 'center',
  },
}));

export default useStyles;
