import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../../../../../types';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, Cell } from 'recharts';
import useStyles from './useStyles';

const NegotiationStatusChart: FC = () => {
  const classes = useStyles();

  const {
    monitoring: {
      negotiationStatus: { negotiationStatus },
    },
  } = useSelector((state: Store) => state);

  const noStatusPv =
    negotiationStatus?.noInitiatedValue +
    negotiationStatus?.ongoingValue +
    negotiationStatus?.finalizedValue;

  const noInitiatedPv = negotiationStatus?.ongoingValue + negotiationStatus?.finalizedValue;

  const ongoingPv = negotiationStatus?.finalizedValue;

  const tableData = [
    {
      name: 'All clients',
      uv: negotiationStatus?.allClientsValue,
      color: '#006B92',
      label: 'Delta to previous monitoring round',
    },
    {
      name: 'No status',
      uv: negotiationStatus?.noStatusValue,
      pv: noStatusPv,
      color: '#E3E4E4',
      label: `${negotiationStatus?.noStatusDelta > 0 ? '+' : ''}${
        negotiationStatus?.noStatusDelta
      }% pts`,
    },
    {
      name: 'Not initiated',
      uv: negotiationStatus?.noInitiatedValue,
      pv: noInitiatedPv,
      color: 'rgba(178, 230, 236, 0.4)',
      label: `${negotiationStatus?.noInitiatedDelta > 0 ? '+' : ''}${
        negotiationStatus?.noInitiatedDelta
      }% pts`,
    },
    {
      name: 'Ongoing',
      uv: negotiationStatus?.ongoingValue,
      pv: ongoingPv,
      color: 'rgba(178, 230, 236, 0.4)',
      label: `${negotiationStatus?.ongoingDelta > 0 ? '+' : ''}${
        negotiationStatus?.ongoingDelta
      }% pts`,
    },
    {
      name: 'Finished',
      uv: negotiationStatus?.finalizedValue,
      color: 'rgba(178, 230, 236, 0.4)',
      label: `${negotiationStatus?.finalizedDelta > 0 ? '+' : ''}${
        negotiationStatus?.finalizedDelta
      }% pts`,
    },
  ];

  //eslint-disable-next-line
  const renderCustomBarLabel = (props: any) => {
    const { x, y, width, name } = props;
    const labelValueObj = tableData.find((item) => item.name === name);
    return (
      <text
        fontWeight={700}
        x={x + width / 2}
        y={y}
        fill="#666"
        textAnchor="middle"
        dy={-6}>{`${labelValueObj?.uv}%`}</text>
    );
  };

  return (
    <div className={classes.negotiationStatusChart}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={300}
          height={300}
          data={tableData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid vertical={false} horizontal={false} />
          <XAxis dataKey="name" tick={{ strokeWidth: 2, fontSize: 14 }} />
          <Bar
            isAnimationActive={false}
            stackId="a"
            dataKey="pv"
            fill="transparent"
            barSize={41}
            radius={[4, 4, 4, 4]}
          />
          <Bar
            isAnimationActive={false}
            stackId="a"
            dataKey="uv"
            fill="#B2E6EC"
            barSize={41}
            radius={[4, 4, 4, 4]}
            label={renderCustomBarLabel}>
            {tableData.map((item) => {
              return <Cell key={item.name} fill={item.color} />;
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <div className={classes.xAxisLine} />
      <div className={classes.xAxisLineLabel}>
        {tableData.map((item) => (
          <div key={item.name} className={classes.itemLabel}>
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NegotiationStatusChart;
