import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSurveysCompleting,
  setSurveyCompletingPlanBOption,
  setSurveysCompletingAdditionalFields,
  setSurveysCompletingContractCommitmentOption,
  setSurveysCompletingEOIOption,
  setSurveysCompletingTouchableOption,
  surveysCompletingSaveProgress,
  setSurveysCompletingOtherOption,
  surveyResultCopyOptionsFromFirstBUToOtherAccountManager,
} from 'src/modules/surveys/actions';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Store } from 'src/types';
import { SurveyResultProvider } from '../../../ConfigureLevelsLayout/components/SurveyResult/SurveyResultProvider';
import { SurveyResultTable } from '../../../ConfigureLevelsLayout/components/SurveyResult/components/SurveyResultTable';

import { ButtonBase } from 'src/components/common/ButtonBase';
import { COLORS } from 'src/constants/COLORS';
import { Loader } from 'src/components/common/Loader';
import { ScreenNames } from 'src/constants/ScreenNames';
import { NoDataPlaceholder } from 'src/components/common/NoDataPlaceholder';

import useStyles from './useStyles';
import { Pagination } from 'src/components/common/Pagination';

export const SurveyCompletingResult: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classes = useStyles();

  const { surveyCompleting, loading } = useSelector((state: Store) => state.surveys);
  const { screenNames, loading: buttonLoading } = useSelector((state: Store) => state.app);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const goToPage = useCallback((page: number) => {
    setPage(page);
  }, []);

  const setTouchableOption = useCallback(
    (body) => {
      dispatch(setSurveysCompletingTouchableOption.request(body));
    },
    [dispatch],
  );

  const setEOIOption = useCallback(
    (body) => {
      dispatch(setSurveysCompletingEOIOption.request(body));
    },
    [dispatch],
  );

  const setOtherOption = useCallback(
    (body) => {
      dispatch(setSurveysCompletingOtherOption.request(body));
    },
    [dispatch],
  );

  const setContractCommitments = useCallback(
    (body) => {
      dispatch(setSurveysCompletingContractCommitmentOption.request(body));
    },
    [dispatch],
  );

  const setAdditionalFields = useCallback(
    (body) => {
      dispatch(setSurveysCompletingAdditionalFields.request(body));
    },
    [dispatch],
  );

  const setPlanBOption = useCallback(
    (body) => {
      dispatch(setSurveyCompletingPlanBOption.request(body));
    },
    [dispatch],
  );

  const goNext = useCallback(() => {
    navigate('/survey/monitoring-survey');
  }, [navigate]);

  const onClickComplete = useCallback(() => {
    dispatch(surveysCompletingSaveProgress.request(ScreenNames.SURVEY_RESULT));
  }, [dispatch]);

  const copyBuValueGeneralInfo = useCallback(
    (requestConfig) => {
      dispatch(
        surveyResultCopyOptionsFromFirstBUToOtherAccountManager.request({
          ...requestConfig,
          pageNumber: page === 0 ? page : page - 1,
          itemsOnPage: rowsPerPage,
        }),
      );
    },
    [dispatch],
  );

  const getSurveyCompleting = useCallback(
    ({ pageNumber, itemsOnPage }: { itemsOnPage: number; pageNumber: number }) => {
      dispatch(
        getSurveysCompleting.request({
          pageNumber,
          itemsOnPage,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    getSurveyCompleting({
      pageNumber: page === 0 ? page : page - 1,
      itemsOnPage: rowsPerPage,
    });
  }, [dispatch, getSurveyCompleting, page, rowsPerPage]);

  const isDisabled = screenNames.find((screen) => screen.screenName === ScreenNames.SURVEY_RESULT);

  return (
    <div>
      <Typography variant="h2">Surveys result</Typography>

      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '400px',
            width: '100%',
            position: 'absolute',
            zIndex: 10,
          }}>
          <Loader isLoading={true} />
        </div>
      )}

      {surveyCompleting && surveyCompleting.surveyResultData ? (
        <>
          <SurveyResultProvider.Provider
            value={{
              additionalFields: surveyCompleting.surveyResultData.additionalFields,
              disableEditPage: isDisabled ? !isDisabled.allowModifying : false,
              surveyResultEOIOptions:
                surveyCompleting.surveyResultData.options.easeOfIncreaseOptions,
              surveyResultContractCommitmentOptions:
                surveyCompleting.surveyResultData.options.contractCommitmentOptions,
              surveyResultPlanBOptions: surveyCompleting.surveyResultData.options.planBOptions,
              surveyResultTouchableOptions:
                surveyCompleting.surveyResultData.options.touchableOptions,
              surveyResultBusinessUnits: surveyCompleting.surveyResultData.businessUnits,
              surveyResultCustomersData: surveyCompleting.surveyResultData.customersData,
              surveyResultOtherOptions: surveyCompleting.surveyResultData.options.otherOptions,
            }}>
            <SurveyResultTable
              copyBuValueGeneralInfo={copyBuValueGeneralInfo}
              setAdditionalFields={setAdditionalFields}
              setPlanBOption={setPlanBOption}
              setEOIOption={setEOIOption}
              setOtherOption={setOtherOption}
              setContractCommitments={setContractCommitments}
              setTouchableOption={setTouchableOption}
              reloadRows={loading}
            />
          </SurveyResultProvider.Provider>
          <Pagination
            totalPages={surveyCompleting.pageable.totalPages}
            setRowPerPage={handleChangeRowsPerPage}
            setPage={handleChangePage}
            page={page}
            rowPerPage={rowsPerPage}
            marginTop={4}
            goToPage={goToPage}
          />
        </>
      ) : (
        <NoDataPlaceholder text="No data yet. Please try again later." />
      )}

      <Stack spacing={6} direction="row" marginTop={3.5} justifyContent="flex-end">
        {surveyCompleting && surveyCompleting.surveyResultData.customersData.length > 0 && (
          <ButtonBase
            disabled={isDisabled && !isDisabled.allowModifying}
            containerStyle={classes.button}
            variant="contained"
            title="Complete"
            isLoading={buttonLoading}
            onClick={onClickComplete}
          />
        )}

        {screenNames.length > 1 && (
          <ButtonBase
            iconColor={COLORS.red1}
            variant="contained"
            title="Second survey"
            onClick={goNext}
            icon="next"
          />
        )}
      </Stack>
    </div>
  );
};
