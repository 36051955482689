import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    display: 'flex',
    columnGap: 20,
    justifyContent: 'flex-end',
    paddingTop: 20,
  },
  button: {
    '& button': {
      width: 120,
    },
  },
}));

export default useStyles;
