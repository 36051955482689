import { createReducer } from 'deox';
import { AppState } from './types';
import { ROLES } from 'src/constants/ROLES';
import {
  changeRoleForRedirectToUnauthorized,
  login,
  loginSSO,
  removeToken,
  saveManagerLoginLink,
  updateTemplateMail,
  clearTemplateForEmail,
  getTemplateMail,
} from './actions';
import { produce } from 'immer';

import { surveysCompletingSaveProgress } from 'src/modules/surveys/actions';

import { submitSecondSurvey } from 'src/modules/surveys/actions';

const initialState: AppState = {
  loading: false,
  currentRole: ROLES.ROLE_INITIAL,
  screenNames: [],
  token: '',
  isBattleCards: false,
  managerLoginLink: '',
  templateMailData: {
    body: '',
    closing: '',
    greeting: '',
    intro: '',
    subject: '',
  },
};

export const appReducer = createReducer(initialState, (handleAction) => [
  handleAction(submitSecondSurvey.success, (state) =>
    produce(state, (draft) => {
      if (state.screenNames.length > 0) {
        draft.screenNames = state.screenNames.map((screen) =>
          screen.screenName === 'SECOND_SURVEY' ? { ...screen, allowModifying: false } : screen,
        );
      }
    }),
  ),
  handleAction(login.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(login.success, (state, { payload }) =>
    produce(state, (draft) => {
      const roleAccountManager = payload?.roles.find(
        (item: string) => item === ROLES.ROLE_ACCOUNT_MANAGER,
      );
      draft.loading = false;
      draft.currentRole = roleAccountManager ? roleAccountManager : state.currentRole;
      draft.token = payload.token;
      draft.screenNames = payload.screenNames;
      draft.isBattleCards = payload.isBattleCards;
    }),
  ),
  handleAction(loginSSO.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(removeToken, (state) =>
    produce(state, (draft) => {
      draft.token = '';
    }),
  ),
  handleAction(loginSSO.success, (state, { payload }) =>
    produce(state, (draft) => {
      const roleAdmin = payload?.roles.find((item: string) => item === ROLES.ROLE_ADMIN);
      draft.loading = false;
      draft.currentRole = roleAdmin ? roleAdmin : state.currentRole;
      draft.token = payload.token;
      // draft.screenNames = payload.screenNames;
      // draft.isBattleCards = payload.isBattleCards;
    }),
  ),
  handleAction(loginSSO.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(login.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  //
  handleAction(surveysCompletingSaveProgress.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(surveysCompletingSaveProgress.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.screenNames = state.screenNames.map((item) =>
        item.screenName === payload
          ? {
              ...item,
              allowModifying: false,
            }
          : item,
      );
      draft.loading = false;
    }),
  ),
  handleAction(surveysCompletingSaveProgress.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(changeRoleForRedirectToUnauthorized, (state, { payload }) =>
    produce(state, (draft) => {
      draft.currentRole = payload;
    }),
  ),
  handleAction(saveManagerLoginLink, (state, { payload }) =>
    produce(state, (draft) => {
      draft.managerLoginLink = payload as string;
    }),
  ),
  handleAction(getTemplateMail.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getTemplateMail.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.templateMailData = payload;
    }),
  ),
  handleAction(getTemplateMail.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(updateTemplateMail.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(updateTemplateMail.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.templateMailData = payload;
    }),
  ),
  handleAction(updateTemplateMail.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(clearTemplateForEmail, (state) =>
    produce(state, (draft) => {
      draft.templateMailData = {
        body: '',
        closing: '',
        greeting: '',
        intro: '',
        subject: '',
      };
    }),
  ),
]);
