import { axiosInstance } from './axiosInstance';
import {
  GetAccountManagersListRequestParams,
  GetBusinessUnitsRequestParams,
  GetCustomerRegistryRequestParams,
  GetSurveyStatusesRequestParams,
  SendSurveyToAccountManagerRequestBody,
  SetAccountManagerRequestParams,
  GetRevenueBaseRequestParams,
  SetTargetForBusinessUnitsRequestBody,
  UpdatePricePotentialRequestBody,
  DeleteCostDriverRequestParams,
  DeletePricePotentialCategory,
  DeletePricePotentialIndicator,
  GetSurveySettingsByIdRequest,
  UpdateSurveysSettingsRequestBody,
  DeletePriceIncreaseTimeLevelRequestParams,
  DeleteSurveySettingsOptionRequestParams,
  GetSurveyResultRequestParams,
  SurveyResultSetTouchableOptionRequestBody,
  SurveyResultSetEOIOptionRequestBody,
  SurveyResultSetContractCommitment,
  SurveyResultSetAdditionalFields,
  SurveyResultSetPlanBOptions,
  GetPricePotentialMatrixRequestParams,
  UpdatePricePotentialAdditionalFieldsRequest,
  UpdatePricePotentialCostDrivers,
  UpdatePricePotentialMatrixIndicator,
  UpdatePricePotentialMatrixWeights,
  SetProgressCheckboxValueRequestBody,
  GetProjectShowModalRequestParams,
  SetProgressShowModalRequestBody,
  UpdateSurveySettingTouchable,
  GetProgressCheckboxValueRequestParams,
  SurveySettingCreateNewEOI,
  SurveySettingUpdateEOIOption,
  SurveySettingUpdateEOIModalInfo,
  UpdateAccountManagersTable,
  UpdateBusinessUnitsTable,
  UpdateCustomerRegistryTable,
  UpdateTargetForTargetSettings,
  GetEOIWeighting,
  UpdateEOIWeightingRequestBody,
  GetBusinessCaseAssumptionsRequestParams,
  UpdateBusinessCaseAssumptions,
  GetTargetFineTuningRequestParams,
  UpdateTargetFineTuningRequestConfig,
  UpdateCustomerTargetCell,
  GetAllProjectsRequestParams,
  AddNewProjectRequest,
  EditProjectRequestBody,
  LoginRequestBody,
  SendSurveyToAllRequestParams,
  UpdateSecondFinalCustomerTargetsRealizedPrice,
  ResetOverwriteImpactEffectParams,
  UpdateAdditionalFieldsOfSurveySettings,
  UpdateCustomerRevenues,
  GetNegotiationProcessRequestParams,
  GetMonitoringSurveyRequestParams,
  GetAccountManagerGroupsRequestParams,
  SurveyResultCopyOptionsFromFirstBUToOtherRequestConfig,
  GetNegotiationStatusRequest,
  UpdateConfOfSecondSurvey,
  GetInfoForMonitoringScreenRequestParams,
  UpdateMonitoringScreenByAdminParams,
  GetSecondSurveyDataRequestParams,
  SaveSecondSurveyRequestParams,
  SubmitSecondSurveyRequestBody,
  GetPerformanceBreakdownRequest,
  GetCustomerTargetsRequestParams,
  GetSurveyCompleting,
  GetBattleCardsCustomersRequestParams,
  GetBattleCardRequestParams,
  UpdateObjectionHandlingRequestParams,
  CreateObjectionHandlingRequestParams,
  DeleteObjectionHandlingRequestParams,
  UpdateValueArgumentationRequestParams,
  CreateValueArgumentationRequestParams,
  DeleteValueArgumentationRequestParams,
  CreateConcessionsMatrixRequestParams,
  CreateUpdatePriceIncreaseBridgeRequestParams,
  DeletePriceIncreaseBridgeRequestParams,
  DownloadSurveyResultParamsRequest,
  GetBattleCardsMailStatusesRequestParams,
  SendBattleCardsMailStatusesToAllRequestParams,
  SendBattleCardsToAccountManagerRequestParams,
  GetMonitoringDatesRequest,
  UpdateTemplateMailRequestParams,
  TemplateMailRequestParams,
  CreateSurveySettingsOptionRequest,
  UpdateSurveySettingsOptionRequest,
  SurveySettingUpdateOtherModalInfo,
  DeleteSurveyOtherModalOptionRequestParams,
  SurveyResultSetOtherOptionRequestBody,
} from 'src/types/requests';

export const API = {
  login: (body: LoginRequestBody) => {
    return axiosInstance.instance!.post('login/v1/email', body);
  },
  loginSSO: (code: string) => {
    return axiosInstance.instance!.get(`login/v1/token?code=${code}`);
  },
  getSettingsOverview: (params: { projectId: number }) => {
    return axiosInstance.instance!.get('configure-levels/v1/settings', { params });
  },
  getBusinessUnits: (params: GetBusinessUnitsRequestParams) => {
    return axiosInstance.instance!.get('configure-levels/v1/business-units', { params });
  },
  getAccountManagersList: (params: GetAccountManagersListRequestParams) => {
    return axiosInstance.instance!.get('configure-levels/v1/account-managers', { params });
  },
  getCustomerRegistry: (params: GetCustomerRegistryRequestParams) => {
    return axiosInstance.instance!.get('configure-levels/v1/customer-registry', { params });
  },
  exportBusinessUnitsExcel: (
    file: FormData,
    params: { itemsOnPage: number; sortBy: string; projectId: number },
  ) => {
    return axiosInstance.instance!.post('configure-levels/v1/business-units/upload-excel', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params,
    });
  },
  checkSurveyResultForEmptyFields: (params: { projectId: number }) => {
    return axiosInstance.instance!.get('surveys/result/v1/check', { params });
  },
  downloadBusinessUnitsExcel: (params: { projectId: number }) => {
    return axiosInstance.instance!.get('configure-levels/v1/business-units/export-excel-template', {
      responseType: 'blob',
      params: { ...params },
    });
  },
  downloadAccountManagerListTemplate: (params: { projectId: number }) => {
    return axiosInstance.instance!.get(
      'configure-levels/v1/account-managers/export-excel-template',
      {
        responseType: 'blob',
        params: { ...params },
      },
    );
  },
  exportAccountManagersList: (
    file: FormData,
    params: { itemsOnPage: number; projectId: number },
  ) => {
    return axiosInstance.instance!.post('configure-levels/v1/account-managers/upload-excel', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params,
    });
  },

  downloadCustomerRegistryExcel: (params: { projectId: number; years: string }) => {
    return axiosInstance.instance!.get(
      'configure-levels/v1/customer-registry/export-excel-template',
      {
        responseType: 'blob',
        params: { ...params },
      },
    );
  },
  exportCustomerRegistryExcel: (
    file: FormData,
    params: { projectId: number; itemsOnPage: number },
  ) => {
    return axiosInstance.instance!.post(
      'configure-levels/v1/customer-registry/upload-excel',
      file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: { ...params },
      },
    );
  },
  getAccountManagerNames: (params: { projectId: number }) => {
    return axiosInstance.instance!.get('configure-levels/v1/account-manager-names', { params });
  },
  setAccountManager: (data: SetAccountManagerRequestParams) => {
    return axiosInstance.instance!.post(
      'configure-levels/v1/customer-registry/set-account-manager',
      data.body,
      { params: data.params },
    );
  },
  sendSurveyToAll: (params: SendSurveyToAllRequestParams) => {
    return axiosInstance.instance!.post('mail/send-to-all', undefined, { params });
  },
  getSurveyStatuses: (params: GetSurveyStatusesRequestParams) => {
    return axiosInstance.instance!.get('configure-levels/v1/survey-status', { params });
  },
  sendSurveyToAccountManager: (
    body: SendSurveyToAccountManagerRequestBody,
    params: { surveyNumber: number },
  ) => {
    return axiosInstance.instance!.post('mail/send-to-account-manager', body, { params });
  },
  getRevenueBaseCharts: (params: GetRevenueBaseRequestParams) => {
    return axiosInstance.instance!.get('campaign/v1/revenue-base', { params });
  },
  getTargetsForBusinessUnits: (params: { projectId: number }) => {
    return axiosInstance.instance!.get('campaign/v1/target', { params });
  },
  setTargetForBusinessUnits: (
    body: SetTargetForBusinessUnitsRequestBody,
    params: { projectId: number },
  ) => {
    return axiosInstance.instance!.post('campaign/v1/target', body, { params });
  },
  getPricePotential: (params: { projectId: number }) => {
    return axiosInstance.instance!.get('campaign/v1/price-potential', { params });
  },
  updatePricePotential: (body: UpdatePricePotentialRequestBody, params: { projectId: number }) => {
    return axiosInstance.instance!.post('campaign/v1/price-potential', body, { params });
  },
  deleteCostDriver: (params: DeleteCostDriverRequestParams) => {
    return axiosInstance.instance!.delete('campaign/v1/price-potential/cost-drivers', { params });
  },
  deletePricePotentialCategory: (params: DeletePricePotentialCategory) => {
    return axiosInstance.instance!.delete('campaign/v1/price-potential/categories', { params });
  },
  deletePricePotentialIndicator: (params: DeletePricePotentialIndicator) => {
    return axiosInstance.instance!.delete('campaign/v1/price-potential/indicators', { params });
  },
  getSurveysSettingsById: (params: GetSurveySettingsByIdRequest) => {
    return axiosInstance.instance!.get(`surveys/settings/v1/${params.id}`);
  },
  surveySettingsCreateNewEOI: (body: SurveySettingCreateNewEOI) => {
    return axiosInstance.instance!.post('surveys/settings/v1/eoi-option', body);
  },
  surveySettingsUpdateEOIOption: (body: SurveySettingUpdateEOIOption) => {
    return axiosInstance.instance!.patch('surveys/settings/v1/eoi-option', body);
  },
  surveySettingUpdateEOIModalInfo: (params: SurveySettingUpdateEOIModalInfo) => {
    return axiosInstance.instance!.post('surveys/settings/v1/eoi-option/modal', params.body, {
      params: params.params,
    });
  },
  surveySettingUpdateOtherModalInfo: (params: SurveySettingUpdateOtherModalInfo) => {
    return axiosInstance.instance!.post(
      `surveys/settings/v1/other-option/${params.params.optionId}/items`,
      params.body,
    );
  },
  updateSurveysSettingsById: (body: UpdateSurveysSettingsRequestBody) => {
    return axiosInstance.instance!.post(`surveys/settings/v1`, body);
  },
  deleteSurveyPriceIncreaseTimeLevelOption: (params: DeletePriceIncreaseTimeLevelRequestParams) => {
    return axiosInstance.instance!.delete(
      'surveys/settings/v1/options/price-increase-time-levels',
      { params },
    );
  },
  deleteSurveyOtherModalOption: (params: DeleteSurveyOtherModalOptionRequestParams) => {
    const { itemId, optionId } = params;
    return axiosInstance.instance!.delete(
      `surveys/settings/v1/other-option/${itemId}/items/${optionId}`,
    );
  },
  updateSurveySettingTouchable: (params: UpdateSurveySettingTouchable) => {
    return axiosInstance.instance!.patch('surveys/settings/v1/touchable', undefined, { params });
  },
  deleteSurveySettingsOption: (params: DeleteSurveySettingsOptionRequestParams) => {
    return axiosInstance.instance!.delete('surveys/settings/v1/options', { params });
  },
  createSurveySettingsOtherOption: (payloadRequest: CreateSurveySettingsOptionRequest) => {
    const { params, body } = payloadRequest;
    return axiosInstance.instance!.post('surveys/settings/v1/other-option', body, { params });
  },
  updateSurveySettingsOtherOption: (payloadRequest: UpdateSurveySettingsOptionRequest) => {
    const body = {
      option: payloadRequest.option,
    };
    return axiosInstance.instance!.patch(
      `surveys/settings/v1/other-option/${payloadRequest.optionId}`,
      body,
    );
  },
  getSurveyResult: (params: GetSurveyResultRequestParams) => {
    return axiosInstance.instance!.get(`surveys/result/v1/${params.projectId}`, {
      params: {
        accountManager: params.accountManager,
        itemsOnPage: params.itemsOnPage,
        pageNumber: params.pageNumber,
      },
    });
  },
  surveyResultSetTouchableOption: (
    id: number,
    params: { accountManager: string },
    body: SurveyResultSetTouchableOptionRequestBody,
  ) => {
    return axiosInstance.instance!.patch(`surveys/result/v1/${id}/touchable-option`, body, {
      params,
    });
  },
  surveyResultSetEOIOption: (
    projectId: number,
    params: { accountManager: string },
    body: SurveyResultSetEOIOptionRequestBody,
  ) => {
    return axiosInstance.instance!.patch(
      `surveys/result/v1/${projectId}/ease-of-increase-option`,
      body,
      {
        params,
      },
    );
  },
  surveyResultSetOtherOption: (
    projectId: number,
    params: { accountManager: string },
    body: SurveyResultSetOtherOptionRequestBody,
  ) => {
    return axiosInstance.instance!.patch(`surveys/result/v1/${projectId}/other-option`, body, {
      params,
    });
  },
  surveyResultSetContractCommitment: (
    id: number,
    params: { accountManager: string },
    body: SurveyResultSetContractCommitment,
  ) => {
    return axiosInstance.instance!.patch(
      `surveys/result/v1/${id}/contract-commitment-option`,
      body,
      { params },
    );
  },
  surveyResultSetAdditionalFields: (
    id: number,
    params: { accountManager: string },
    body: SurveyResultSetAdditionalFields,
  ) => {
    return axiosInstance.instance!.patch(`surveys/result/v1/${id}/additional-fields`, body, {
      params,
    });
  },
  surveyResultSetPlanBOption: (
    id: number,
    params: { accountManager: string },
    body: SurveyResultSetPlanBOptions,
  ) => {
    return axiosInstance.instance!.patch(`surveys/result/v1/${id}/plan-b-option`, body, { params });
  },
  surveyResultCopyOptionsFromFirstBUToOther: (
    requestConfig: SurveyResultCopyOptionsFromFirstBUToOtherRequestConfig,
  ) => {
    const { params, body } = requestConfig;

    return axiosInstance.instance!.patch(`surveys/result/v1/${params?.projectId}/copy-value`, body);
  },
  surveyResultCopyOptionsFromFirstBUToOtherAccountManager: (
    requestConfig: SurveyResultCopyOptionsFromFirstBUToOtherRequestConfig,
  ) => {
    const { body } = requestConfig;

    return axiosInstance.instance!.patch(`surveys-completing/result/v1/copy-value`, body);
  },
  getBusinessUnitsName: (params: { projectId: number }) => {
    return axiosInstance.instance!.get('configure-levels/v1/business-unit-name', { params });
  },
  downloadSurveyResultExcel: (projectId: number, params: DownloadSurveyResultParamsRequest) => {
    return axiosInstance.instance!.get(`surveys/result/v1/${projectId}/excel`, {
      responseType: 'blob',
      params,
    });
  },
  getPricePotentialMatrix: (params: GetPricePotentialMatrixRequestParams) => {
    return axiosInstance.instance!.get('campaign/v1/price-potential-matrix', { params });
  },
  updatePricePotentialAdditionalFields: (params: UpdatePricePotentialAdditionalFieldsRequest) => {
    return axiosInstance.instance!.patch(
      'campaign/v1/price-potential-matrix/additional-fields',
      params.body,
      {
        params: params.params,
      },
    );
  },
  updatePricePotentialMatrixCostDriver: (params: UpdatePricePotentialCostDrivers) => {
    return axiosInstance.instance!.patch(
      'campaign/v1/price-potential-matrix/cost-drivers',
      params.body,
      {
        params: params.params,
      },
    );
  },
  updatePricePotentialMatrixIndicator: (params: UpdatePricePotentialMatrixIndicator) => {
    return axiosInstance.instance!.patch(
      'campaign/v1/price-potential-matrix/indicators',
      params.body,
      {
        params: params.params,
      },
    );
  },
  updatePricePotentialMatrixWeight: (params: UpdatePricePotentialMatrixWeights) => {
    return axiosInstance.instance!.patch(
      'campaign/v1/price-potential-matrix/weights',
      params.body,
      {
        params: params.params,
      },
    );
  },
  setProgressCheckboxValue: (body: SetProgressCheckboxValueRequestBody) => {
    return axiosInstance.instance!.post('progress/checkbox-value', body);
  },
  getProgressShowModal: (params: GetProjectShowModalRequestParams) => {
    return axiosInstance.instance!.get('progress/show-modal', { params });
  },
  setProgressShowModal: (body: SetProgressShowModalRequestBody) => {
    return axiosInstance.instance!.post('progress/show-modal', body);
  },
  getProgressCheckboxValue: (params: GetProgressCheckboxValueRequestParams) => {
    return axiosInstance.instance!.get('progress/checkbox-value', { params });
  },
  updateAccountManagersTable: (params: UpdateAccountManagersTable) => {
    return axiosInstance.instance!.patch('configure-levels/v1/account-manager', undefined, {
      params,
    });
  },
  updateBusinessUnitsTable: (params: UpdateBusinessUnitsTable) => {
    return axiosInstance.instance!.patch('configure-levels/v1/business-unit', undefined, {
      params,
    });
  },
  updateCustomerRegistryTable: (params: UpdateCustomerRegistryTable) => {
    return axiosInstance.instance!.patch('configure-levels/v1/customer', undefined, { params });
  },
  getTargetSettings: (params: { projectId: number }) => {
    return axiosInstance.instance!.get('campaign/v1/target-settings', { params });
  },
  updateTargetForTargetSettings: (
    body: UpdateTargetForTargetSettings,
    params: { projectId: number },
  ) => {
    return axiosInstance.instance!.patch('campaign/v1/target-settings', body, { params });
  },
  getEOIWeighting: (params: GetEOIWeighting) => {
    return axiosInstance.instance!.get('campaign/v1/eoi-indicator-weighting', { params });
  },
  getBUAbbreviations: (params: { projectId: number }) => {
    return axiosInstance.instance!.get('configure-levels/v1/business-unit-abbreviations', {
      params,
    });
  },
  updateEOIWeighting: (body: UpdateEOIWeightingRequestBody, params: { projectId: number }) => {
    return axiosInstance.instance!.patch('campaign/v1/eoi-indicator-weighting', body, { params });
  },
  getBusinessCaseAssumptions: (params: GetBusinessCaseAssumptionsRequestParams) => {
    return axiosInstance.instance!.get('campaign/v1/business-case-assumptions', { params });
  },
  updateBusinessCaseAssumption: (requestConfig: {
    body: UpdateBusinessCaseAssumptions;
    params: { projectId: number };
  }) => {
    return axiosInstance.instance!.patch(
      'campaign/v1/business-case-assumptions',
      requestConfig.body,
      { params: requestConfig.params },
    );
  },
  getTargetFineTuning: (params: GetTargetFineTuningRequestParams) => {
    return axiosInstance.instance!.get('campaign/v1/target-fine-tuning', { params });
  },
  updateTargetFineTuning: (requestConfig: UpdateTargetFineTuningRequestConfig) => {
    const { params, body } = requestConfig;

    return axiosInstance.instance!.patch('campaign/v1/target-fine-tuning', body, { params });
  },
  exportCustomerTargetsExcel: (params: { file: FormData; projectId: number }) => {
    return axiosInstance.instance!.post('campaign/v1/customer-targets/upload-excel', params.file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        projectId: params.projectId,
      },
    });
  },
  downloadCustomerTargetsExcel: (params: { projectId: number }) => {
    return axiosInstance.instance!.get('campaign/v1/customer-targets/export-excel-template', {
      params,
      responseType: 'blob',
    });
  },
  getCustomerTargets: (params: GetCustomerTargetsRequestParams) => {
    return axiosInstance.instance!.get('campaign/v1/customer-targets', { params });
  },
  updateCustomerTargetCell: (requestConfig: {
    params: { projectId: number };
    body: UpdateCustomerTargetCell;
  }) => {
    const { params, body } = requestConfig;

    return axiosInstance.instance!.patch('campaign/v1/customer-targets', body, { params });
  },
  updateCustomerTargetBusinessUnitCell: (requestConfig: {
    params: { projectId: number };
    body: UpdateCustomerTargetCell;
  }) => {
    const { params, body } = requestConfig;

    return axiosInstance.instance!.patch('campaign/v1/customer-targets/business-units', body, {
      params,
    });
  },
  updateExpandedStatusOfCustomerTargetsTable: (params: {
    projectId: number;
    expanded: boolean;
  }) => {
    return axiosInstance.instance!.patch('campaign/v1/customer-targets/expand', undefined, {
      params,
    });
  },
  getCustomerTargetsExpandedStatus: (params: { projectId: number }) => {
    return axiosInstance.instance!.get('campaign/v1/customer-targets/expand', { params });
  },
  getCampaignImpact: (params: { projectId: number }) => {
    return axiosInstance.instance!.get('campaign/v1/campaign-impact', { params });
  },
  downloadFinalCustomerTargetsTemplate: (params: { projectId: number }) => {
    return axiosInstance.instance!.get('campaign/v1/final-customer-targets/export-excel-template', {
      params,
      responseType: 'blob',
    });
  },
  getFinalCustomerTargets: (params: { projectId: number }) => {
    return axiosInstance.instance!.get('campaign/v1/final-customer-targets', { params });
  },
  updateApproachCampaignTarget: (params: { projectId: number; approach: string }) => {
    return axiosInstance.instance!.patch('campaign/v1/campaign-target', undefined, { params });
  },
  getAllProjects: (params: GetAllProjectsRequestParams) => {
    return axiosInstance.instance!.get('projects/v1', { params });
  },
  addNewProject: (requestPayload: AddNewProjectRequest) => {
    const { body, params } = requestPayload;

    return axiosInstance.instance!.post('projects/v1', body, { params });
  },
  deleteProjectById: (params: { projectId: number }) => {
    return axiosInstance.instance!.delete('projects/v1', { params });
  },
  editProject: (body: EditProjectRequestBody) => {
    return axiosInstance.instance!.patch('projects/v1', body);
  },
  getSurveysCompleting: (params: GetSurveyCompleting) => {
    return axiosInstance.instance!.get('surveys-completing/v1', { params });
  },
  setSurveysCompletingAdditionalFields: (body: SurveyResultSetAdditionalFields) => {
    return axiosInstance.instance!.patch('surveys-completing/v1/additional-fields', body);
  },
  setSurveysCompletingContractCommitmentOption: (body: SurveyResultSetContractCommitment) => {
    return axiosInstance.instance!.patch('surveys-completing/v1/contract-commitment-option', body);
  },
  setSurveysCompletingEOIOption: (body: SurveyResultSetEOIOptionRequestBody) => {
    return axiosInstance.instance!.patch('surveys-completing/v1/ease-of-increase-option', body);
  },
  setSurveysCompletingOtherOption: (body: SurveyResultSetOtherOptionRequestBody) => {
    return axiosInstance.instance!.patch('surveys-completing/v1/other-option', body);
  },
  setSurveyCompletingPlanBOption: (body: SurveyResultSetPlanBOptions) => {
    return axiosInstance.instance!.patch('surveys-completing/v1/plan-b-option', body);
  },
  setSurveysCompletingTouchableOption: (body: SurveyResultSetTouchableOptionRequestBody) => {
    return axiosInstance.instance!.patch('surveys-completing/v1/touchable-option', body);
  },
  surveysCompletingSaveProgress: () => {
    return axiosInstance.instance!.patch('surveys-completing/v1/update-status');
  },
  updateSecondFinalCustomerTargetsRealizePrice: (
    params: UpdateSecondFinalCustomerTargetsRealizedPrice,
  ) => {
    return axiosInstance.instance!.patch(
      'surveys-completing/v1/final-customer-targets/business-units',
      undefined,
      { params },
    );
  },
  resetOverwriteImpactEffect: (params: ResetOverwriteImpactEffectParams) => {
    return axiosInstance.instance!.post(
      `campaign/v1/customer-targets/reset?projectId=${params.projectId}&customerId=${params.customerId}`,
      { params },
    );
  },
  getProgressHomepageCards: (params: { projectId: number }) => {
    return axiosInstance.instance!.get('progress/homepage-cards', { params });
  },
  updateAdditionalFieldsOfSurveySettings: (body: UpdateAdditionalFieldsOfSurveySettings) => {
    return axiosInstance.instance!.patch('surveys/settings/v1/additional-fields', body);
  },
  updateCustomerRevenues: (params: UpdateCustomerRevenues) => {
    return axiosInstance.instance!.patch('configure-levels/v1/customer-revenues', undefined, {
      params,
    });
  },
  getApproachForCampaignTarget: (params: { projectId: number }) => {
    return axiosInstance.instance!.get('campaign/v1/campaign-target', { params });
  },
  getAccountManagerGroups: (params: GetAccountManagerGroupsRequestParams) => {
    return axiosInstance.instance!.get('configure-levels/v1/account-manager-groups', { params });
  },
  // Monitoring apis below
  getNegotiationProcess: (payload: GetNegotiationProcessRequestParams) => {
    const body = {
      accountManagers: payload.accountManager,
      businessUnitAbbreviations: payload.segment,
    };
    const params = {
      projectId: payload.projectId,
      targetLine: payload.targetLine,
    };

    return axiosInstance.instance!.post('monitoring/negotiation-process', body, { params });
  },
  getNegotiationStatus: (payloadRequest: GetNegotiationStatusRequest) => {
    const { body, params, signal } = payloadRequest;
    return axiosInstance.instance!.post('monitoring/negotiation-status', body, { params, signal });
  },
  getPerformanceBreakdown: (payloadRequest: GetPerformanceBreakdownRequest) => {
    const { body, params } = payloadRequest;
    return axiosInstance.instance!.post('monitoring/performance-breakdown', body, {
      params,
    });
  },
  getMonitoringDates: (params: GetMonitoringDatesRequest) => {
    return axiosInstance.instance!.get('monitoring/date-list', {
      params,
    });
  },
  downloadMonitoringExcel: (params: { projectId: number; date: string }) => {
    return axiosInstance.instance!.get('monitoring/customers/excel', {
      responseType: 'blob',
      params: { ...params },
    });
  },
  getMonitoringSurvey: (params: GetMonitoringSurveyRequestParams) => {
    return axiosInstance.instance!.get('monitoring/monitoring-survey', { params });
  },
  updateConfOfSecondSurvey: (requestConfig: UpdateConfOfSecondSurvey) => {
    return axiosInstance.instance!.patch('monitoring/second-survey-config', requestConfig.body, {
      params: requestConfig.params,
    });
  },
  getConfOfSecondSurvey: (params: { projectId: number }) => {
    return axiosInstance.instance!.get('monitoring/second-survey-config', { params });
  },
  getInfoForMonitoringScreen: (params: GetInfoForMonitoringScreenRequestParams) => {
    return axiosInstance.instance!.get('monitoring/customers', { params });
  },
  updateMonitoringByAdmin: (body: UpdateMonitoringScreenByAdminParams, projectId: number) => {
    return axiosInstance.instance!.patch('monitoring/customers', body, {
      params: { projectId },
    });
  },
  getSecondSurveyData: (params: GetSecondSurveyDataRequestParams) => {
    return axiosInstance.instance!.get('surveys-completing/v1/customers', { params });
  },
  saveSecondSurvey: (body: SaveSecondSurveyRequestParams) => {
    return axiosInstance.instance!.patch('surveys-completing/v1/customers/save', body);
  },
  submitSecondSurvey: (body: SubmitSecondSurveyRequestBody) => {
    return axiosInstance.instance!.patch('surveys-completing/v1/customers/submit', body);
  },
  checkWeightingsOfBusinessUnits: (params: { projectId: number }) => {
    return axiosInstance.instance!.get('campaign/v1/eoi-indicator-weighting/check', { params });
  },
  getSurveyResultAccess: (params: { projectId: number }) => {
    return axiosInstance.instance!.get('progress/survey-result-access', { params });
  },
  getBattleCardsCustomersForAdmin: (params: GetBattleCardsCustomersRequestParams) => {
    return axiosInstance.instance!.get('battle-cards/v1/customers', { params });
  },
  getBattleCardsCustomersForAccountManager: (params: GetBattleCardsCustomersRequestParams) => {
    return axiosInstance.instance!.get('battle-cards-completing/v1/customers', { params });
  },
  getBattleCard: (params: GetBattleCardRequestParams) => {
    return axiosInstance.instance!.get(
      `battle-cards/v1/customers/${params.customerId}/battle-card`,
      { params },
    );
  },
  getBattleCardForAccountManager: (params: GetBattleCardRequestParams) => {
    return axiosInstance.instance!.get(
      `battle-cards-completing/v1/customers/${params.customerId}/battle-card`,
      { params },
    );
  },
  updateObjectionHandling: (payloadRequest: UpdateObjectionHandlingRequestParams) => {
    const { params, body } = payloadRequest;
    return axiosInstance.instance!.patch(
      `battle-cards/v1/customers/${params.customerId}/objection-handling`,
      body,
      { params },
    );
  },
  updateObjectionHandlingForAccountManager: (
    payloadRequest: UpdateObjectionHandlingRequestParams,
  ) => {
    const { params, body } = payloadRequest;
    return axiosInstance.instance!.patch(
      `battle-cards-completing/v1/customers/${params.customerId}/objection-handling`,
      body,
      { params },
    );
  },
  createObjectionHandling: (payloadRequest: CreateObjectionHandlingRequestParams) => {
    const { params, body } = payloadRequest;
    return axiosInstance.instance!.post(
      `battle-cards/v1/customers/${params.customerId}/objection-handling`,
      body,
      { params },
    );
  },
  createObjectionHandlingForAccountManager: (
    payloadRequest: CreateObjectionHandlingRequestParams,
  ) => {
    const { params, body } = payloadRequest;
    return axiosInstance.instance!.post(
      `battle-cards-completing/v1/customers/${params.customerId}/objection-handling`,
      body,
      { params },
    );
  },
  deleteObjectionHandling: (params: DeleteObjectionHandlingRequestParams) => {
    return axiosInstance.instance!.delete(
      `battle-cards/v1/customers/${params.customerId}/objection-handling/${params.fieldId}`,
      { params },
    );
  },
  deleteObjectionHandlingForAccountManager: (params: DeleteObjectionHandlingRequestParams) => {
    return axiosInstance.instance!.delete(
      `battle-cards-completing/v1/customers/${params.customerId}/objection-handling/${params.fieldId}`,
      { params },
    );
  },
  updateValueArgumentation: (payloadRequest: UpdateValueArgumentationRequestParams) => {
    const { params, body } = payloadRequest;
    return axiosInstance.instance!.patch(
      `battle-cards/v1/customers/${params.customerId}/value-argumentation`,
      body,
      { params },
    );
  },
  updateValueArgumentationForAccountManager: (
    payloadRequest: UpdateValueArgumentationRequestParams,
  ) => {
    const { params, body } = payloadRequest;
    return axiosInstance.instance!.patch(
      `battle-cards-completing/v1/customers/${params.customerId}/value-argumentation`,
      body,
      { params },
    );
  },
  createValueArgumentation: (payloadRequest: CreateValueArgumentationRequestParams) => {
    const { params, body } = payloadRequest;
    return axiosInstance.instance!.post(
      `battle-cards/v1/customers/${params.customerId}/value-argumentation`,
      body,
      { params },
    );
  },
  createValueArgumentationForAccountManager: (
    payloadRequest: CreateValueArgumentationRequestParams,
  ) => {
    const { params, body } = payloadRequest;
    return axiosInstance.instance!.post(
      `battle-cards-completing/v1/customers/${params.customerId}/value-argumentation`,
      body,
      { params },
    );
  },
  deleteValueArgumentation: (params: DeleteValueArgumentationRequestParams) => {
    return axiosInstance.instance!.delete(
      `battle-cards/v1/customers/${params.customerId}/value-argumentation/${params.fieldId}`,
      { params },
    );
  },
  deleteValueArgumentationForAccountManager: (params: DeleteValueArgumentationRequestParams) => {
    return axiosInstance.instance!.delete(
      `battle-cards-completing/v1/customers/${params.customerId}/value-argumentation/${params.fieldId}`,
      { params },
    );
  },
  createConcessionsMatrix: (payloadRequest: CreateConcessionsMatrixRequestParams) => {
    const { params, body } = payloadRequest;
    return axiosInstance.instance!.post(
      `battle-cards/v1/customers/${params.customerId}/concessions-matrix`,
      body,
      { params },
    );
  },
  createConcessionsMatrixForAccountManager: (
    payloadRequest: CreateConcessionsMatrixRequestParams,
  ) => {
    const { params, body } = payloadRequest;
    return axiosInstance.instance!.post(
      `battle-cards-completing/v1/customers/${params.customerId}/concessions-matrix`,
      body,
      { params },
    );
  },
  updatePriceIncreaseBridgeContractTerm: (
    payloadRequest: CreateUpdatePriceIncreaseBridgeRequestParams,
  ) => {
    const { params, body } = payloadRequest;
    return axiosInstance.instance!.patch(
      `battle-cards/v1/customers/${params.customerId}/contract-term`,
      body,
      { params },
    );
  },
  updatePriceIncreaseBridgeContractTermForAccountManager: (
    payloadRequest: CreateUpdatePriceIncreaseBridgeRequestParams,
  ) => {
    const { params, body } = payloadRequest;
    return axiosInstance.instance!.patch(
      `battle-cards-completing/v1/customers/${params.customerId}/contract-term`,
      body,
      { params },
    );
  },
  updatePriceIncreaseBridgePlanB: (
    payloadRequest: CreateUpdatePriceIncreaseBridgeRequestParams,
  ) => {
    const { params, body } = payloadRequest;
    return axiosInstance.instance!.patch(
      `battle-cards/v1/customers/${params.customerId}/plan-b`,
      body,
      { params },
    );
  },
  updatePriceIncreaseBridgePlanBForAccountManager: (
    payloadRequest: CreateUpdatePriceIncreaseBridgeRequestParams,
  ) => {
    const { params, body } = payloadRequest;
    return axiosInstance.instance!.patch(
      `battle-cards-completing/v1/customers/${params.customerId}/plan-b`,
      body,
      { params },
    );
  },
  createPriceIncreaseBridgeContractTerm: (
    payloadRequest: CreateUpdatePriceIncreaseBridgeRequestParams,
  ) => {
    const { params, body } = payloadRequest;
    return axiosInstance.instance!.post(
      `battle-cards/v1/customers/${params.customerId}/contract-term`,
      body,
      { params },
    );
  },
  createPriceIncreaseBridgeContractTermForAccountManager: (
    payloadRequest: CreateUpdatePriceIncreaseBridgeRequestParams,
  ) => {
    const { params, body } = payloadRequest;
    return axiosInstance.instance!.post(
      `battle-cards-completing/v1/customers/${params.customerId}/contract-term`,
      body,
      { params },
    );
  },
  createPriceIncreaseBridgePlanB: (
    payloadRequest: CreateUpdatePriceIncreaseBridgeRequestParams,
  ) => {
    const { params, body } = payloadRequest;
    return axiosInstance.instance!.post(
      `battle-cards/v1/customers/${params.customerId}/plan-b`,
      body,
      { params },
    );
  },
  createPriceIncreaseBridgePlanBForAccountManager: (
    payloadRequest: CreateUpdatePriceIncreaseBridgeRequestParams,
  ) => {
    const { params, body } = payloadRequest;
    return axiosInstance.instance!.post(
      `battle-cards-completing/v1/customers/${params.customerId}/plan-b`,
      body,
      { params },
    );
  },
  deletePriceIncreaseBridgeContractTerm: (params: DeletePriceIncreaseBridgeRequestParams) => {
    return axiosInstance.instance!.delete(
      `battle-cards/v1/customers/${params.customerId}/contract-term/${params.fieldId}`,
      { params },
    );
  },
  deletePriceIncreaseBridgeContractTermForAccountManager: (
    params: DeletePriceIncreaseBridgeRequestParams,
  ) => {
    return axiosInstance.instance!.delete(
      `battle-cards-completing/v1/customers/${params.customerId}/contract-term/${params.fieldId}`,
      { params },
    );
  },
  deletePriceIncreaseBridgePlanB: (params: DeletePriceIncreaseBridgeRequestParams) => {
    return axiosInstance.instance!.delete(
      `battle-cards/v1/customers/${params.customerId}/plan-b/${params.fieldId}`,
      { params },
    );
  },
  deletePriceIncreaseBridgePlanBForAccountManager: (
    params: DeletePriceIncreaseBridgeRequestParams,
  ) => {
    return axiosInstance.instance!.delete(
      `battle-cards-completing/v1/customers/${params.customerId}/plan-b/${params.fieldId}`,
      { params },
    );
  },
  getBattleCardsMailStatuses: (params: GetBattleCardsMailStatusesRequestParams) => {
    return axiosInstance.instance!.get('battle-cards/v1/status', { params });
  },
  sendBattleCardsMailStatusesToAll: (params: SendBattleCardsMailStatusesToAllRequestParams) => {
    return axiosInstance.instance!.post('battle-cards/v1/send-to-all', undefined, { params });
  },
  sendBattleCardsToAccountManager: (params: SendBattleCardsToAccountManagerRequestParams) => {
    return axiosInstance.instance!.post('battle-cards/v1/send', undefined, { params });
  },

  getTemplateMail: (params: TemplateMailRequestParams) => {
    return axiosInstance.instance!.get('mail/template', { params });
  },
  updateTemplateMail: (payloadRequest: UpdateTemplateMailRequestParams) => {
    const { params, body } = payloadRequest;
    return axiosInstance.instance!.post('mail/template', body, { params });
  },
};
