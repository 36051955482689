import React from 'react';
import {
  GetMonitoringInfoScreeResponse,
  GetSecondSurveyResponse,
  UpdateMonitoringScreenByAdminParams,
} from 'src/types';
import { NoDataPlaceholder } from '../NoDataPlaceholder';
import useStyles from './useStyles';
import { MONITORING_SCREEN_STATUSES } from '../../../constants/MONITORING_SCREEN_STATUSES';
import CommentEdit from './CommentEdit';
import { EditableCell } from '../EditableCell';
import { Dropdown } from '../Dropdown';
import { COLORS } from '../../../constants/COLORS';

const monitoringStatuses = [
  {
    value: 'Not initiated',
    color: '#FF5982',
    id: 0,
  },
  {
    value: 'Ongoing',
    color: '#E5BF18',
    id: 1,
  },
  {
    value: 'Finished',
    color: '#00A887',
    id: 2,
  },
  {
    value: 'No status',
    color: COLORS.gray3,
    id: 3,
  },
];

type Props = {
  data: GetMonitoringInfoScreeResponse | null | GetSecondSurveyResponse;
  allowModifying?: boolean;
  onEditCustomerRow?: (data: {
    customerId: number;
    status?: string;
    businessUnitId?: number;
    percentage?: number;
    comment?: string;
  }) => void;
  onUpdateMonitoringByAdmin?: (data: UpdateMonitoringScreenByAdminParams) => void;
};

export const MonitoringTable: React.FC<Props> = ({
  data,
  allowModifying,
  onEditCustomerRow,
  onUpdateMonitoringByAdmin,
}) => {
  const classes = useStyles();

  if (!data) {
    return <NoDataPlaceholder text="No data yet. Please try again later." />;
  }

  const { businessUnitAbbreviations, customerData } = data;

  const getStatusByEnumValue = (status: string) => {
    switch (status) {
      case MONITORING_SCREEN_STATUSES.NOT_INITIATED:
        return 'Not initiated';
      case MONITORING_SCREEN_STATUSES.ONGOING:
        return 'Ongoing';
      case MONITORING_SCREEN_STATUSES.FINISHED:
        return 'Finished';
      case MONITORING_SCREEN_STATUSES.NO_STATUS:
        return 'No status';
      default:
        return null;
    }
  };

  return (
    <div className={classes.container}>
      <table className={classes.table}>
        <thead className={classes.tHeader}>
          <tr className={classes.tHeaderRow}>
            <th rowSpan={2}>
              <div
                style={{
                  height: 90,
                  display: 'flex',
                  alignItems: 'flex-end',
                  paddingBottom: 14,
                  paddingLeft: 10,
                }}>
                Customer
              </div>
            </th>
            <th colSpan={businessUnitAbbreviations.length}>Target Price Increase</th>
            <th />
            <th colSpan={businessUnitAbbreviations.length}>Expected / Realized Price Increase</th>
            <th />
          </tr>
          <tr className={classes.tHeaderRow}>
            {businessUnitAbbreviations.map((item) => (
              <th key={item}>{item}</th>
            ))}
            <th>Status</th>
            {businessUnitAbbreviations.map((item) => (
              <th key={item}>{item}</th>
            ))}
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          {customerData.map((customer) => (
            <tr className={classes.tBodyRow} key={customer.id}>
              <td>
                <div>{customer.name}</div>
              </td>
              {customer.targetPriceIncreases.map((target) => (
                <td key={target.businessUnitId}>
                  <div style={{ width: 100 }}>{target.percentage}%</div>
                </td>
              ))}
              <td>
                <Dropdown
                  showRounds
                  disabled={onEditCustomerRow ? !allowModifying : false}
                  containerStyle={classes.dropdownContainer}
                  width={170}
                  dropdownItems={[]}
                  dropdownItemsWithObjects={monitoringStatuses}
                  defaultItem={getStatusByEnumValue(customer.status)}
                  onSelectCb={(statusId) => {
                    if (onEditCustomerRow) {
                      onEditCustomerRow({
                        customerId: customer.id,
                        status: Object.values(MONITORING_SCREEN_STATUSES)[+statusId],
                      });
                    }

                    if (onUpdateMonitoringByAdmin) {
                      onUpdateMonitoringByAdmin({
                        id: customer.id,
                        status: Object.values(MONITORING_SCREEN_STATUSES)[+statusId],
                      });
                    }
                  }}
                />
              </td>
              {customer.expectedOutOfRealizedIncreases?.map((target) => (
                <EditableCell
                  innerInputStyle={classes.editableCell}
                  cellStyle={classes.editableCell}
                  buttonStyle={classes.editableCell}
                  suffix="%"
                  onlyNumbers
                  disabled={target.disabled}
                  disableEditing={target.disabled}
                  key={target.businessUnitId}
                  value={target.percentage ? target.percentage.toString() : '0'}
                  onEditCb={(value) => {
                    if (onEditCustomerRow) {
                      onEditCustomerRow({
                        customerId: customer.id,
                        businessUnitId: target.businessUnitId,
                        percentage: +value,
                      });
                    }

                    if (onUpdateMonitoringByAdmin) {
                      onUpdateMonitoringByAdmin({
                        id: customer.id,
                        expectedOutOfRealizedIncreases: [
                          {
                            businessUnitId: target.businessUnitId,
                            percentage: +value,
                          },
                        ],
                      });
                    }
                  }}
                />
              ))}
              <td>
                <CommentEdit
                  disabled={!allowModifying}
                  onUpdateMonitoringByAdmin={onUpdateMonitoringByAdmin}
                  onLocalUpdate={
                    onEditCustomerRow
                      ? (comment) => {
                          if (onEditCustomerRow) {
                            onEditCustomerRow({
                              customerId: customer.id,
                              comment,
                            });
                          }
                        }
                      : undefined
                  }
                  customer={customer}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
