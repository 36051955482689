import React, { FC } from 'react';
import { battleCardColors } from 'src/constants/BATTLE_CARD_COLORS';
import useStyles from './useStyles';

type NegotiationPowerProps = {
  pricePotential: number;
};

const NegotiationPower: FC<NegotiationPowerProps> = ({ pricePotential }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.title}>Negotiation power and pricing potential</div>
      <div className={classes.chartContainer}>
        <div className={classes.chart}>
          {battleCardColors.map((color) => (
            <div key={color} className={classes.item} style={{ backgroundColor: color }} />
          ))}
          <div className={classes.indicator} style={{ right: `${pricePotential || 0}%` }}>
            <div className={classes.indicatorText}>{pricePotential}% Pricing potential</div>
          </div>
          <div className={classes.chartTextTop}>Company has full power</div>
          <div className={classes.chartTextBottom}>Client has full power</div>
        </div>
      </div>
    </div>
  );
};

export default NegotiationPower;
